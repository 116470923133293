<template functional>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.1665 13.3333H10.8332V5.83332H13.3332L9.99984 1.66666L6.6665 5.83332H9.1665V13.3333Z" fill="currentColor"/>
    <path d="M4.16667 18.3333H15.8333C16.7525 18.3333 17.5 17.5858 17.5 16.6667V9.16667C17.5 8.2475 16.7525 7.5 15.8333 7.5H12.5V9.16667H15.8333V16.6667H4.16667V9.16667H7.5V7.5H4.16667C3.2475 7.5 2.5 8.2475 2.5 9.16667V16.6667C2.5 17.5858 3.2475 18.3333 4.16667 18.3333Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconExport'
}
</script>

<style>
</style>
